import { __decorate } from "tslib";
// Watch
import { Component, Vue } from 'vue-property-decorator';
import { userToolStore, veiculoStore } from '@/store';
let VeiculoList = class VeiculoList extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                text: 'Capacidade',
                sortable: true,
                value: 'capacidade',
                align: 'left',
            },
            {
                text: 'Placa',
                sortable: true,
                value: 'placa',
                align: 'left',
            },
            {
                text: 'Nome do motorista',
                sortable: true,
                value: 'motorista_nome',
                align: 'left',
            },
            {
                text: 'Telefone do motorista',
                sortable: true,
                value: 'motorista_telefone',
                align: 'left',
            },
            {
                text: 'Observação',
                sortable: true,
                value: 'observacao',
                align: 'left',
            },
            {
                text: 'Ativo',
                sortable: true,
                value: 'is_active',
                align: 'left',
            },
            {
                text: 'Ações',
                value: 'actions',
            },
        ];
        this.loading = true;
        this.search = '';
        this.selectedItem = {};
    }
    get allowView() {
        if (userToolStore.userToolsIndexed.Veículos) {
            return userToolStore.userToolsIndexed.Veículos[0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed.Veículos) {
            return userToolStore.userToolsIndexed.Veículos[0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed.Veículos) {
            return userToolStore.userToolsIndexed.Veículos[0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed.Veículos) {
            return userToolStore.userToolsIndexed.Veículos[0].allow_delete;
        }
    }
    get veiculos() {
        return veiculoStore.veiculos;
    }
    async deleteItem(id) {
        this.$swal({
            title: 'Confirma a exclusão do veículo?',
            text: 'Após exclusão o veículo não pode ser recuperado!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.loading = true;
                // @ts-ignore
                await veiculoStore.deleteVeiculo(id);
                // @ts-ignore
                await veiculoStore.getVeiculos();
                this.loading = false;
            }
        });
    }
    async mounted() {
        this.loading = true;
        await veiculoStore.getVeiculos();
        this.loading = false;
    }
    routeEdit(id) {
        this.$router.push({ name: 'main-veiculos-edit', params: { id } });
    }
};
VeiculoList = __decorate([
    Component
], VeiculoList);
export default VeiculoList;

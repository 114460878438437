var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Veículos")]),_c('v-spacer'),(_vm.allowCreate)?_c('v-btn',{attrs:{"to":"/main/TransporteVeiculos/create","color":"primary"}},[_vm._v("Criar")]):_vm._e()],1),_c('v-card-text',[[_c('v-text-field',{attrs:{"label":"Buscar","single-line":"","append-icon":"mdi-magnify","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"ma-1",attrs:{"multi-sort":"","headers":_vm.headers,"items":_vm.veiculos,"search":_vm.search,"loading-text":"Carregando...","loading":_vm.loading,"fixed-header":"","height":"50vh","footer-props":{
            itemsPerPageOptions:[-1]
          },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.valor",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPhone")(item.motorista_telefone))+" ")]}},{key:"item.is_active",fn:function(ref){
          var item = ref.item;
return [(item.is_active)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(_vm.allowEdit)?_c('v-icon',{on:{"click":function($event){return _vm.routeEdit(item.id)}}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.allowDelete)?_c('v-icon',{on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v("mdi-delete")]):_vm._e()]}}],null,true)})]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }